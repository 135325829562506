import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import * as S from './CsrModal.styles'
import {
  yupScheme,
  config,
  initialValues,
  uploadInitialValues,
  uploadYupScheme,
} from './CsrModal.config'
import { i18n } from '../../../i18n'
import { Loader } from '../../Loader'
import { UploadCsrSuccessModal } from '../UploadCsrSuccessModal'
import slugify from 'react-slugify'
import { countries } from '../../../constants/countries'

const validationTypes = [
  {
    value: 'email',
    label: 'Email Validation',
  },
  { value: 'file', label: 'File validation' },
]

const validationEmails = [
  {
    value: 'admin@yourdomain.am',
    label: 'admin@yourdomain.am',
  },
  {
    value: 'administrator@yourdomain.am',
    label: 'administrator@yourdomain.am',
  },
  {
    value: 'webmaster@yourdomain.am',
    label: 'webmaster@yourdomain.am',
  },
  {
    value: 'postmaster@yourdomain.am',
    label: 'postmaster@yourdomain.am',
  },
  {
    value: 'hostmaster@yourdomain.am',
    label: 'hostmaster@yourdomain.am',
  },
]

export const CsrModal = ({
  showModal,
  hideModal,
  loading,
  generateCsr,
  productId,
  tab,
  setTab,
  step,
  setStep,
  uploadLoading,
  uploadCsr,
  csrResult,
  setIsKeyDownloaded,
  isKeyDownloaded,
  uploadError,
  isGenerateOnlyModal,
  setCSR,
  isWildcard,
}) => {
  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  const containerRef = useRef(null)

  const downloadFile = (fileName, val) => {
    const element = document.createElement('a')
    const file = new Blob([val], { type: 'text/plain;charset=utf-8' })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }
  const [uploadInfo, setUploadInfo] = useState()
  const [countriesData, setCountriesData] = useState()

  const formikRef = useRef()

  useEffect(() => {
    setCountriesData(
      countries.map((item) => {
        return {
          ...item,
          label: `${item.label} (${item.value})`,
        }
      })
    )
  }, [])

  useEffect(() => {
    if (formikRef && formikRef.current)
      formikRef.current.setFieldValue('isWild', !!isWildcard)
  }, [isWildcard])

  return (
    <S.Modal>
      <S.CsrModalContainer>
        {!!isGenerateOnlyModal && (
          <S.Tab>{i18n.t('csrModal.generateCsr')}</S.Tab>
        )}
        {!isGenerateOnlyModal && <S.Tab>{i18n.t('csrModal.uploadCsr')}</S.Tab>}

        {!isGenerateOnlyModal && tab === 0 && (
          <>
            <Formik
              initialValues={uploadInitialValues}
              validationSchema={uploadYupScheme}
              onSubmit={async (values, { setSubmitting }) => {
                // uploadCsr(
                //   {
                //     ...values,
                //     productid: productId,
                //   },
                //   () => {
                //     showModal(UploadCsrSuccessModal)
                //   }
                // )
                // setSubmitting(false)
                setCSR('csr', values.csr)
                setCSR('privateKey', '')
                setTimeout(() => {
                  hideModal()
                }, 50)
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleChange,
                submitForm,
                values,
                setFieldValue,
              }) => (
                <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
                  <S.Textarea
                    as="textarea"
                    name="csr"
                    value={values.csr}
                    onChange={handleChange}
                    placeholder={i18n.t('csrModal.copyPasteCsrHere')}
                  ></S.Textarea>
                  {uploadError && (
                    <S.ErrorMessage>
                      {i18n.t(`backend.uploadError.${slugify(uploadError)}`)}
                    </S.ErrorMessage>
                  )}
                  <S.SubmitButton type="submit">
                    {uploadLoading ? (
                      <Loader color="#FFFFFF" size={0.5} />
                    ) : (
                      i18n.t('csrModal.button')
                    )}
                  </S.SubmitButton>
                </S.StyledForm>
              )}
            </Formik>
          </>
        )}
        {(isGenerateOnlyModal || tab === 1) && (
          <>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              innerRef={formikRef}
              validationSchema={yupScheme}
              onSubmit={async (values, { setSubmitting }) => {
                delete values.isWild
                const formattedValues = {
                  ...values,
                  country: values?.country.value,
                }
                setUploadInfo({ ...formattedValues })
                generateCsr(
                  {
                    ...formattedValues,
                  },

                  () => {
                    setTimeout(() => {
                      hideModal()
                    }, 10)
                  }
                )

                setSubmitting(false)
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleChange,
                submitForm,
                setFieldValue,
                values,
              }) => {
                return (
                  <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
                    {config &&
                      config.map((item) => (
                        <S.FormItem key={item.id}>
                          <S.Label>{i18n.t(item.name)}</S.Label>
                          {item.mask ? (
                            <S.StyledSelect
                              value={values.country}
                              defaultValue={{ value: 'AM', label: 'Armenia' }}
                              isSearchable={true}
                              name={item.id}
                              options={countriesData}
                              onChange={(option) => {
                                setFieldValue('country', option)
                              }}
                              classNamePrefix="react-select"
                            />
                          ) : (
                            <S.Input
                              name={item.id}
                              placeholder={
                                item.isChangeable && isWildcard
                                  ? '*.example.com'
                                  : item.placeholder
                              }
                              value={values[item.name]}
                              onChange={handleChange}
                            />
                          )}
                          {errors[item.id] && touched[item.id] && (
                            <S.ErrorMessage>
                              {i18n.t(errors[item.id])}
                            </S.ErrorMessage>
                          )}
                        </S.FormItem>
                      ))}
                    <S.SubmitButton type="submit">
                      {loading ? (
                        <Loader color="#FFFFFF" size={0.5} />
                      ) : (
                        i18n.t('csrModal.button')
                      )}
                    </S.SubmitButton>
                  </S.StyledForm>
                )
              }}
            </Formik>

            <S.CloseModalButton onClick={hideModal} />
          </>
        )}
      </S.CsrModalContainer>
    </S.Modal>
  )
}

//........
{
  /* <S.FormItem>
                      <S.Label>{i18n.t('csrModal.validationType')}</S.Label>
                      <S.StyledSelect
                        value={validationTypes.find(
                          (item) => item.value === values.validationType
                        )}
                        isSearchable={true}
                        name={'validationType'}
                        options={validationTypes}
                        onChange={(option) => {
                          setFieldValue('validationType', option.value)
                        }}
                        classNamePrefix="react-select"
                      />
                    </S.FormItem> */
}
{
  /* {values.validationType === 'email' && (
                        <S.FormItem>
                          <S.Label>{i18n.t('csrModal.validationEmail')}</S.Label>
                          <S.StyledSelect
                            value={validationEmails.find(
                              (item) => item.value === values.validationEmail
                            )}
                            isSearchable={true}
                            name={'validationEmail'}
                            options={validationEmails}
                            onChange={(option) => {
                              setFieldValue('validationEmail', option.value)
                            }}
                            classNamePrefix="react-select"
                          />
                        </S.FormItem>
                      )}
                      {values.validationType === 'file' && (
                        <div style={{ marginTop: '20px' }}>
                          {i18n.t('csrModal.fileValidation')}
                        </div>
                      )} */
}
